import React from 'react';
import Helmet from "react-helmet";
import Nav from '../components/Nav';
import Footer from "../components/Footer";

import '../styles/styles.scss';
import OGImage from '../images/ogimage.jpg';

const seo = {
  title: 'All your F&B suppliers in one app. SupplyMe – B2B marketplace',
  description: 'The app simplifies ordering, payment, and reduces paperwork with your suppliers.',
  url: 'https://supplyme.ae',
  image: `https://supplyme.ae${OGImage}`,
};

const Layout = ({ children, fullgreen, title }) => (
  <div className={`layout${fullgreen ? ' layout-green' : ''}`}>
    <Helmet defer={false}>
      <title>{title ? `${title} | ` : ''}SupplyMe. All Your F&amp;B Suppliers in One App. B2B Marketplace in UAE</title>
      <meta name="description" content={seo.description} />
      {seo.image && <meta name="image" content={seo.image} />}
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
    <Nav />
    {children}
    <Footer />
  </div>
);

export default Layout;