import React from "react";
import FacebookIcon from "../images/svg/facebook.svg";
// import TwitterIcon from "../images/svg/twitter.svg";
// import InstagramIcon from "../images/svg/instagram.svg";
import YoutubeIcon from "../images/svg/youtube.svg";
import LinkedInIcon from "../images/svg/linkedin.svg";

const Social = () => (
  <ul className="social">
    <li>
      <a href="https://www.linkedin.com/company/supplymeapp">
        <LinkedInIcon />
      </a>
    </li>
    <li>
      <a href="https://www.facebook.com/supplyme.ae">
        <FacebookIcon />
      </a>
    </li>
    {/* <li>
      <a href="https://twitter.com/supplymeapp">
        <TwitterIcon />
      </a>
    </li>
    <li>
      <a href="https://instagram.com/supplyme">
        <InstagramIcon />
      </a>
    </li> */}
    <li>
      <a href="https://www.youtube.com/channel/UCZl0-l6WS3sLGpVNxwaddQQ">
        <YoutubeIcon />
      </a>
    </li>
  </ul>
);

export default Social;
